import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import projectList from '../asset/data/projectList';
import { FaGithub } from 'react-icons/fa';


const IndividualProjectPages = () => {
    //read aftre the / in the url
    const url = window.location.pathname.split('/').pop();
    const urlProject = projectList.find((project) => project.link === `/projects/${url}`);
    if (urlProject) {
        return (
            <>
        <div
            style={{
            minHeight: "100vh",
            display: "flex",
            background: "linear-gradient(215deg, #00d4ff 0%, #082b8f 5%, #031842 25%, #000 50%, #031842 75%, #082b8f 95%, #00d4ff 100%)",
            margin: "0",
            position: "fixed",
            width: "100vw",
            zIndex: "-1"
            }}
        >
            
        </div>
        <section className="py-3 py-md-5"
            style={{
            paddingTop: "0px", marginTop: "0px", backgroundColor: "", maxWidth: "100vw"
            }}
        >
            <Container
            style={{
                backgroundColor: "white", paddingTop: "20px", paddingBottom: "20px", rounded: "true", borderRadius: "20px",
            }}
            >
            <Row>
            <div className="project-page">
            <h1
                style={{
                color: "var(--bs-primary)",
                textAlign: "center",
                marginBottom: "20px",
                }}
            >{urlProject.title}</h1>
            {urlProject.image && (
                <img src={urlProject.image} alt={urlProject.title} 
                style={{ display: 'block', margin: '0 auto' }}
                />
            )}

            <p style={{ color: 'var(--job-display-border-color)', fontWeight: 'bold' }}>{urlProject.description}</p>
            <p>{urlProject.longDescription}</p>
            {urlProject.details && (
                Object.entries(urlProject.details).map(([key, value], index) => (
                <p key={index}>
                <span style={{ fontWeight: 'bold' }}>{key}: </span>
                {value}
                </p>
                ))
            )}

            <p><span style={{ fontWeight: 'bold' }}>Outils utilisés:  </span>{urlProject.tools.join(', ')}</p>

            {urlProject.github && (
                urlProject.github.map((github, index) => (
                <Button variant="dark"
                href={github} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}>
                <FaGithub style={{ marginRight: '10px' }} />
                GitHub
                </Button>
                ))
            )}
            </div>
            </Row>
            <Row>
            <Col>
            <Button href="/projects" variant="secondary">&lt; Retour à la page des projets</Button>
            </Col>
            </Row>
            </Container>
            </section>

        </>
        );
    }

    window.location.href = '/projects';
    return null;



};

export default IndividualProjectPages;