import React from 'react';
import { 
    Container, Row, Col, Button
} from 'react-bootstrap';
import CV from '../asset/download/CVAlcorMONLLOR.pdf';

import { FaLinkedin, FaGithub , FaFilePdf} from 'react-icons/fa';

const handleDownload = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const Link = () => {
    return (
        <Container>
            <Row className="my-5">
                <Col md={12} className="text-center">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <Button href="https://www.linkedin.com/in/alcor-monllor/" className="btn btn-primary mx-2">
                                <FaLinkedin /> LinkedIn
                            </Button>
                        </li>
                        <li className="list-inline-item">
                            <Button href="https://github.com/ManderVoronwe" className="btn btn-dark mx-2">
                                <FaGithub /> GitHub
                            </Button>
                        </li>
                        <li className="list-inline-item">
                            <Button href={CV} onClick={() => handleDownload(CV, 'CV-Alcor-MONLLOR.pdf')} className="btn btn-danger mx-2">
                                <FaFilePdf /> Télécharger mon CV
                            </Button>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default Link;

