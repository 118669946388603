import React, { useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const Collapsible = ({ children, style }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const toggleCollapsible = () => {
        setIsOpen(!isOpen);
    };

    let header = null;
    let content = null;

    React.Children.forEach(children, child => {
        if (child.type === Collapsible.Header) {
            header = child;
        } else if (child.type === Collapsible.Content) {
            content = child;
        }
    });

    return (
        <Card style={style}>
            <Card.Header
                onClick={toggleCollapsible}
                style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    ...((header.props.style) || {}),
                }}
            >
                {isOpen ? (
                    <FaChevronDown style={{ marginRight: "10px" }} />
                ) : (
                    <FaChevronRight style={{ marginRight: "10px" }} />
                )}
                <Card.Title
                    style={{
                        margin: "auto",
                        padding: "0",
                    }}
                
                >
                    {header}
                </Card.Title>
                    
                
                
            </Card.Header>
            <div
                ref={contentRef}
                style={{
                    height: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
                    overflow: "hidden",
                    transition: "height 0.3s ease-out",
                }}
            >
                <Card.Body style={(content.props.style) || {}}>{content}</Card.Body>
            </div>
        </Card>
    );
};

Collapsible.Header = ({ children, style }) => <div style={style || {}}>{children}</div>;
Collapsible.Content = ({ children, style }) => <div style={style || {}}>{children}</div>;

export default Collapsible;
