import React from 'react';
import { Card, Button } from 'react-bootstrap';

const ProjectCard = ({ title, description, link, image, tools, github, CardCarousel = false }) => {

    return (
        <Card className="project-card"
            style={{ minHeight: '25rem', border: 'none', display: 'flex', flexDirection: 'column' , 
                height: '100%',
            }}
        >
            
            {image && (
                <Card.Img variant="top" src={image} style={{ height: '9rem', objectFit: 'cover' }} alt={"image décorative projet " + title} />
            )}
            {!image && <div style={{ height: '9rem' }} />}

            <Card.Body style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingTop: '0' }}>
                <Card.Title
                    style={{
                        backgroundColor: 'var(--job-display-border-color)',
                        padding: '5px 20px',
                        marginTop: '-17px',
                        borderRadius: '10px',
                        width: 'fit-content',
                        color: 'var(--bs-light)',
                    }}
                >
                    {title}
                </Card.Title>
                <Card.Text style={{ color: 'black' }}
                >
                    {description}
                </Card.Text>

                {tools && (
                    <div className="mt-3">
                        {tools.map((tool, index) => (
                            <span key={index}
                                style={{ display: 'inline-block', margin: '0.2rem', padding: '0.2rem 0.8rem', borderRadius: '15px', backgroundColor: 'var(--bs-dark)', color: 'var(--bs-light)' }}
                            >
                                {tool}
                            </span>
                        ))}
                    </div>
                )}

                {link && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 'auto',  // Pushes button to bottom
                    }}>
                        <Button variant="primary" href={link} rel="noopener noreferrer"
                            style={{
                                color: 'var(--bs-light)',
                            }}>
                            En savoir plus
                        </Button>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};



export default ProjectCard;

/*{github && (
                    <div>
                        {github && (

                            github.map((github, index) => (

                                <span key={index}>

                                    <Button variant="dark"
                                        href={github} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', marginTop: '10px', backgroundColor: "black" }}>
                                        < FaGithub style={{ marginRight: '10px' ,

                                        }} />
                                        github
                                    </Button>
                                </span>

                            ))
                        )}
                    </div>
                )}*/