import axone_logo from '../images/axone_logo.png';
import insa_logo from '../images/insa_logo.jpeg';
import wavestone_logo from '../images/wavestone_logo.webp';

const jobExperiences = [
	{
		company: 'Wavestone Luxembourg',
		role: 'Consultant stagiaire en Cybersécurité',
		duration: 'Mai 2024 - Oct 2024',
		description: "Lors de mon stage de fin d'études chez Wavestone Luxembourg, j'ai occupé le poste d'analyste en cybersécurité. Mon travail s'est concentré sur l'analyse de l'impact de l'intelligence artificielle (IA) dans le domaine de la cybersécurité. J'ai étudié comment l'IA est utilisée à la fois pour renforcer les défenses des systèmes informatiques et pour développer des menaces sophistiquées.",
		task: [
			"Analyse des Menaces IA : Étude des techniques d'attaques utilisant l'IA, telles que les malwares polymorphes et les deepfakes.",
			"Défense par l'IA : Évaluation des solutions de cybersécurité basées sur l'IA, incluant la détection automatisée des menaces et la réponse rapide aux incidents.",
			"Gestion des Risques : Participation à la mise en place de stratégies de gestion des risques et de conformité pour les systèmes d'IA.",
			"Projets Internes : Développement de solutions pour la sécurité des systèmes d'IA, en collaboration avec des experts de la pratique cybersécurité à Paris.",
			"Mémoire : Rédaction d'un rapport détaillé sur l'impact de l'IA en cybersécurité, incluant des recommandations pour améliorer les défenses des systèmes informatiques.",
			"Collaboration Internationale : Travail en étroite collaboration avec des équipes de cybersécurité à Luxembourg et à Paris, contribuant à des projets stratégiques pour des institutions européennes."
		],
		tools: [
			"Python", "Git", "Hugging face", "Cuda", "GithubCopilot", " Deep Fake", "LLm", "Deep learning", "Pare-feu / Firewall", "Pentests", "Gouvernance"

		],
		image : wavestone_logo

	},
	{
		company: "INSA - CENTRE VAL DE LOIRE",
		role: "Recherche et mise en place d'une solution de monitoring",
		duration: "avril 2023 - aout 2023",
		description: "Conception d'une solution de monitoring pour l'infrastructure VDI VMware Horizon. Utilisation de Zabbix, Grafana et d'autres outils pour surveiller les performances, la disponibilité et la sécurité de l'infrastructure. Introduction d'Ansible dans les processus de la DSI.",
		task: [
			"Prise en main du projet et des outils : Familiarisation avec les technologies et les outils de l'infrastructure VDI.",
			"Recherche et analyse : Compréhension des besoins de surveillance et sélection des outils de monitoring (Zabbix, Grafana).",
			"Conception et mise en œuvre de la solution de monitoring",
			"Définition des métriques à surveiller.",
			"Configuration des alertes et des seuils d'avertissement.",
			"Déploiement de Zabbix pour le monitoring des composants VDI.",
			"Utilisation de Grafana pour la visualisation des données.",
			"Tests et validation : Simulations de scénarios pour vérifier la réactivité et l'exactitude des alertes.",
			"Optimisation : Améliorations sur des applications spécifiques comme Microsoft Teams pour l'infrastructure VDI.",
			"Documentation et formation : Création de la documentation sur la solution déployée et formation de l'équipe à son utilisation.",
		],
		tools: [
			"VMware Horizon", "Zabbix", "Grafana", "Ansible", "Microsoft Teams", "ESXi", "Debian", "Python", "Bash", "GPO", "GitLab", "Git", "Métriques / Indicateurs", "vSphere", "Hyperviseur", "API REST", "SentinelOne", "Alerting"

		],
		image : insa_logo

	},
	{
		company: "Axone",
		role: "Adjoint Ingénieur Méthodes et Outils d'Ingénierie Système",
		duration: "avril 2022 - aout 2022",
		description: "Développement d'un outil digital de gestion de projets complexes en ingénierie système. Implémentation de servlets en Java, optimisation de scripts en JavaScript et Velocity, et amélioration de la performance du code. Mise en place d'un environnement de travail outillé.",
		task: [
			"Prise en main des projets et des outils",
			"Familiarisation avec les projets THINK-IS et Sibelius.",
			"Prise en main des outils de développement et de gestion de projet.",
			"Développement et optimisation de fonctionnalités",
			"Implémentation de servlets en Java pour améliorer les fonctionnalités de Polarion.",
			"Optimisation du code existant en JavaScript et Velocity.",
			"Réécriture des fonctions pour améliorer la performance et la maintenabilité.",
			"Création d'un environnement de travail outillé ",
			"Mise en place d'une machine virtuelle pour le développement.",
			"Configuration de l'environnement de développement .",
			"Documentation et génération de rapports ",
			"Documentation du code avec Doxygen.",
			"Génération de documentation pour les développeurs futurs.",
			"Création de rapports de stage et de présentations internes.",
			"Collaboration avec les équipes de Siemens et ReqConsult.",
			"Support technique pour le projet avec Safran Aerosystems.",
			"Participation à des réunions et présentations internes."

		],
		tools: [
			"Java", "JavaScript", "Velocity", "Eclipse", "VS Code", "Polarion", "git", "Gitea", "Microsoft Azure","PostgreSQL", "SVN", "Doxygen", "Visual Paradigm", "DevOps"," Database Postgres", "JUnit"

		],
		image : axone_logo
	}
];

export default jobExperiences;