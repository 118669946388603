import virtualisation from "../images/virtualisation.png";
import soc from "../images/soc.png";
import athena from "../images/athena.png";
import worldmap from "../images/worldmap.png";

const projectList = [
  {
    image: virtualisation,
    title: "Installation de salle groupe Virtualisation",
    description: "Projet de réinstallation de la salle de cours et des services associés pour l'option de 5ème année.",
    link: "/projects/groupe-virtualisation",
    longDescription: "Dans mon option de 5ème année, les élèves doivent réinstaller l'intégralité de la salle de cours et les services associés. J'ai été en charge avec un collègue de l'infrastructure de virtualisation. Dans le cadre de ce projet, nous avons mis en place un environnement VMware pour la gestion des machines virtuelles. Nous avons choisi VMware ESXi pour sa robustesse et sa popularité dans les environnements professionnels. L'installation a été réalisée sur un serveur rackable avec une configuration de stockage en RAID 5 et RAID 60 pour assurer la redondance et la performance. Nous avons également travaillé sur l'agrégation de liens pour améliorer la connectivité réseau et avons mis en place des VLAN et des ACL pour renforcer la sécurité. Enfin, nous avons implémenté des procédures de sauvegarde et de reprise après sinistre, avec des sauvegardes automatiques hebdomadaires stockées sur un serveur distinct. Ce projet nous a permis d'acquérir des compétences en virtualisation, en gestion de réseaux et en administration de systèmes, tout en nous préparant à des scénarios de reprise après sinistre.",
    tools: ["GhettoVCB", "VMware NSX", "RAID 5", "RAID 60", "vCenter", "VMware ESXi", "Vsphere", "Debian", "iDRAC", "NFS"],
  },
  {
    title: "Gestion de crise",
    description: "Projet purple team par groupe d'étudiants.",
    link: "/projects/Gestion-de-crise",
    longDescription: "Simulation de CERT menée par plusieurs groupes d'étudiants. Format en purple team. Une équipe crée un service web et une infrastructure volontairement vulnérable (2 mois et demi de préparation). Puis donnait pendant 48h le service aux autres groupes pour qu'ils puissent évaluer les services et corriger autant de failles que possible côté web / OS / SGBD et enlever les binaires malveillants. 3 groupes au total dont les rôles s'échangeaient entre les séances.",
    github: [
      "https://github.com/ManderVoronwe/projet-securite-notation",
      "https://github.com/ManderVoronwe/projet-securite-service-web",
    ],
    tools: ["Docker", "Systemd", "PHP", "React", "JavaScript", "SQL", "Debian", "Openstack", "Git", "Github", "Linux"],
  },
  {
    image: athena,
    title: "Projet d'application mobile",
    description: "Projet de création d'une application mobile pour digitaliser le jeu du killer.",
    longDescription: "Le jeu du killer est un jeu grandeur nature populaire. Chaque joueur reçoit le nom d'un autre joueur à éliminer, et doit le faire discrètement pour gagner. Le jeu se poursuit jusqu'à ce qu'il ne reste qu'un seul joueur. L'application mobile que nous avons développée permet de jouer au jeu du killer en automatisant les processus de maître du jeu, attribution aléatoire des cibles, suivi des éliminations en temps réel et réclamation.",
    tools: ["React Native", "NodeJS", "React", "JavaScript", "SQL", "Debian", "PVE", "Terraform", "GitLab", "Proxmox", "Cloudflare", "Ansible", "EntraID", "AzureAD"],
    link: "/projects/application-mobile",
  },
  {
    image: soc,
    title: "Conception d'une architecture SOC",
    description: "Projet de conception d'une architecture SOC pour un projet.",
    longDescription: "Dans le cadre de ce projet, nous avons conçu une architecture SOC pour un projet de sécurité. Nous avons commencé par définir les besoins du client et les exigences de sécurité, puis nous avons élaboré une architecture qui répondait à ces besoins. Nous avons utilisé des outils open source pour la collecte et l'analyse des données, ainsi que pour la détection des menaces. Nous avons également mis en place des procédures de réponse aux incidents et de gestion des vulnérabilités pour garantir la sécurité du système. Ce projet nous a permis d'acquérir des compétences en conception d'architectures de sécurité, en gestion des incidents et en gestion des vulnérabilités, tout en nous préparant à des scénarios de sécurité réels.",
    tools: ["Proxmox", "Cloudflare", "VLAN", "Unifi", "Docker", "DNS", "Suricata", "Splunk", "Debian"],
  },
  {
    image: worldmap,
    title: "Honeypot et SIEM",
    longDescription: "Problèmes rencontrés: Capacité du serveur: Le serveur de virtualisation n'avait pas la capacité requise pour accueillir l'infrastructure, notamment en raison des exigences de T-Pot. Configuration du reverse proxy: Des incertitudes sur la faisabilité et le processus de mise en place d'un proxy ont compliqué la situation. Limitation du volume d'attaques: Initialement, le volume d'attaques était limité, nécessitant une réduction du niveau de sécurité pour encourager plus d'attaques. Réussites: Déploiement de l'infrastructure: L'infrastructure a été déployée avec succès chez moi (sur mon infrastructure personnelle), permettant une surveillance et une analyse efficaces. Collecte de données: Les honeypots ont permis de collecter des données précieuses sur les comportements des attaquants. Pistes d'amélioration: Augmentation de la capacité du serveur: Utilisation de serveurs avec une capacité plus élevée pour éviter les limitations. Documentation claire: Amélioration de la documentation pour faciliter la configuration et la gestion des systèmes. Surveillance continue: Mise en place de mécanismes de surveillance continue pour détecter et répondre rapidement aux menaces potentielles.",
    description: "Projet de mise en place d'un honeypot et d'un SIEM exposé sur internet.",
    tools: ["Docker", "T-Pot", "ELK", "Debian", "Openstack", "Git", "Github", "Kibana", "Elasticsearch", "Suricata", "Cloudflare", "Proxmox"],
  },
  {
    title: "Homelab haute disponibilité",
    description: "Maintien d'un homelab avec NAS et Proxmox VE pour expérimenter des technologies de pointe et renforcer les compétences en cybersécurité.",
    link: "/projects/Homelab-et-haute-disponibilite",
    longDescription: "Mon homelab est une infrastructure dédiée à l'expérimentation, à la virtualisation et à la cybersécurité. Il repose sur Proxmox VE avec un cluster configuré en haute disponibilité et un NAS pour le stockage. L'environnement est segmenté en 50 VLANs pour séparer les différents usages (production, tests de sécurité, DMZ, zone invitée, etc.). Des outils comme Zabbix, Grafana, HomeAssistant et Cloudflare sont utilisés pour la surveillance, la gestion de l'infrastructure et la sécurité. Ce homelab permet de tester des technologies avancées, de simuler des attaques et de mettre en place des stratégies de sécurité de manière réaliste. Le cluster Proxmox, avec un mécanisme UPS, garantit une haute disponibilité. Des systèmes de sauvegarde réguliers et des stratégies de récupération après sinistre sont également en place. En termes d'évolution, l'ajout de deux nouveaux serveurs et l'extension du datastore sont prévus, ainsi que l'intégration de nouvelles technologies.",
    tools: ["Proxmox VE", "NAS", "Zabbix", "Grafana", "HomeAssistant", "Cloudflare", "Virtualisation", "Cybersécurité", "UPS"],
    details: {
      vlan: "50 VLANs pour séparer les usages et isoler les environnements, incluant une DMZ et une zone invitée.",
      cluster: "Cluster Proxmox pour assurer la haute disponibilité avec une infrastructure de failover gérée par Eaton.",
      backup: "Des systèmes de sauvegarde réguliers et des stratégies de récupération après sinistre sont mis en place.",
      experimentation: "L'environnement permet de tester des scénarios de cybersécurité, des configurations réseau complexes, ainsi que l'intégration de nouvelles technologies.",
      resultats: "Amélioration des compétences en administration de virtualisation, gestion de la sécurité et optimisation des réseaux.",
      future: "Des projets futurs incluent l'ajout de deux nouveaux serveurs à l'infrastructure, l'extension du datastore et l'intégration de nouvelles technologies apprises.",
    },
  },
];

export default projectList;
