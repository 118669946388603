import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import projectList from '../asset/data/projectList';
import { Card, Button } from 'react-bootstrap';
import { FaGithub } from 'react-icons/fa';


const ProjectsPage = () => {
  //read aftre the / in the url
  const url = window.location.pathname.split('/').pop();
  //if the url is empty, we are on the projects page
  if (url === 'projects') {
    const columns = [[], [], []];
    projectList.forEach((project, index) => {
      columns[index % 3].push(project);
    });

    return (
      <>

      <Container
      style={{
        padding: "20px",
        backgroundColor: "var(--bs-gray-dark)",
        color: "white",
        margin: "0px",
        maxWidth: "100%",
        minHeight: "100vh",
      }}
      >
      <h1 className="display-4 fw-bold mb-4"
      style={{
        padding: "20px",
        textAlign: "center",
      }}
      >Mes projets</h1>
      <p>Voici une sélection de projets sur lesquels j'ai travaillé, que ce soit dans le cadre de ma formation ou durant mon temps libre. Ces expériences m'ont permis de concevoir et déployer des architectures, d'expérimenter diverses technologies et de perfectionner mes compétences techniques.
        </p>
      <p>
N'hésite pas à explorer ces réalisations et à découvrir les différentes stacks techniques utilisées ! 🚀</p>
      <Row>
        {columns.map((column, colIndex) => (
        <Col md={4} xl={4} xs={12} key={colIndex}
          style={{
            padding: "0px",
          }}
        >
          {column.map((project, index) => {
          const { image, title, description, tools, link, github } = project;
          return (
            <Card className="project-card" key={index} style={{ margin: '20px', border: 'none', display: 'flex', flexDirection: 'column' , marginTop : '40px' 

             }}>
            {image && <Card.Img variant="top" src={image} style={{ objectFit: 'cover' , maxHeight: '25rem' , backgroundColor: 'var(--bs-black)'

            }} 
              alt={"image décorative projet"+{title}}
            />}
            {!image && <div style={{ height: '3rem' }} />}
            <Card.Body style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingTop: '0' }}>
              <Card.Title
              style={{
                backgroundColor: 'var(--job-display-border-color)',
                padding: '5px 20px',
                marginTop: '-17px',
                borderRadius: '10px',
                width: 'fit-content',
                color: 'var(--bs-light)',
            }}
            >{title}</Card.Title>
              <Card.Text
              style={{ }}
              >{description}</Card.Text>
              {tools && (
              <div className="mt-3">
                {tools.map((tool, index) => (
                            <span key={index}
                                style={{ display: 'inline-block', margin: '0.2rem', padding: '0.2rem 0.8rem', borderRadius: '15px', backgroundColor: 'var(--bs-dark)', color: 'var(--bs-light)' }}
                            >
                                {tool}
                            </span>
                        ))}
              </div>
              )}
              {link && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 'auto',  // Pushes button to bottom
                    }}>
                        <Button variant="primary" href={link} rel="noopener noreferrer"
                            style={{
                                color: 'var(--bs-light)',
                            }}>
                            En savoir plus
                        </Button>
                    </div>
                )}
              {github && (
              <div>
                {github.map((github, index) => (
                <span key={index}>
                  <Button
                  variant="dark"
                  href={github}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: '10px', marginTop: '10px' }}
                  >
                  <FaGithub style={{ marginRight: '10px' }} />
                  github
                  </Button>
                </span>
                ))}
              </div>
              )}
            </Card.Body>
            </Card>
          );
          })}
        </Col>
        ))}
      </Row>
      </Container>
      
      </>
    );
  } else {
    return null;
  }



};

export default ProjectsPage;