import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import pp from '../asset/images/pp.jpg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import responsive from '../asset/data/responsive';


const funfacts = [
    {
        title: 'Même pas un pseudo',
        description: 'Alcor est mon vrai prénom, ce n\'est pas un pseudo. Malgré ce que l\'on pourrait penser.'
    },
    {
        title: 'Un vrai pionnier',
        description: '2ème élève ex æquo de l\'INSA Centre Val de Loire à avoir fait un double diplôme STI/MRI. Et le premier à en avoir parlé à l\'administration en 2020.'
    },
    { 
        title: 'Pseudo',
        description: 'Mon pseudo vient de Reflets d\'Acide, que j\'ai découvert en 2013. Je l\'ai gardé depuis. "Maender-Alkoor… Tout commença à Maender..."'
    },
    {
        title: 'Mon prénom',
        description: 'Mon prénom est une référence à la constellation de la Grande Ourse. C\'est une étoile qui servait à tester la vue dans l\'Antiquité.'
    },
    {
        title: 'Avant, j\'étais un aventurier',
        description: 'Puis je me suis pris une flèche dans le genou. (Et oui, il y a une cicatrice.)'
    },
    {
        title: 'J\'adore la science-fiction',
        description: '"La science-fiction est la branche de la littérature qui se soucie des réponses de l\'être humain aux progrès de la science et de la technologie." Isaac Asimov, 1974.'
    }
];



function About() {
	return (
		<>
			<section className="py-3 py-md-5">
				<Bootstrap.Container>
					<Bootstrap.Row className="gy-3 gy-md-4 gy-lg-0 align-items-lg-center" >
						<Bootstrap.Col xs={12} lg={6} xl={5}>
							<img className="img-fluid rounded" loading="lazy" src= {pp} alt="Alcor Monllor" />
						</Bootstrap.Col>
						<Bootstrap.Col xs={12} lg={6} xl={7} >
							<Bootstrap.Row className="justify-content-xl-center">
								<Bootstrap.Col xs={12} xl={11}>
									<h1 className="display-4 fw-bold mb-4" 
									style={{
                                        color: "var(--bs-primary)"
                                    }}
									>
										Qui suis-je ?</h1>
									<p className="lead fs-4 text-secondary mb-3">
										Je suis Alcor Monllor, ingénieur double diplômé en cybersécurité et maîtrise du risque industriel.
									</p>
									<p className="mb-5"> Diplômé de l'INSA Centre Val de Loire dans les domaines de la cybersécurité et de la maitrise du risque industriel. Avec un profile technique et prêt à apprendre de nouveau sujet, je cherche mon premier emploi en cybersécurité.
									</p>
									<Bootstrap.Row className="gy-4 gy-md-0 gx-xxl-5X">
										<Bootstrap.Col xs={12} md={6}>
											<div className="d-flex">
						
												<div>
													<h2 className="me-4"
													style={{
														color: "var(--job-display-border-color)"
													}}
	
													>
														Cybersécurité
													</h2>
													<p className="text-secondary mb-0">
														La cybersécurité est un domaine qui me passionne et dans lequel je souhaite travailler.
													</p>
												</div>
											</div>
										</Bootstrap.Col>
										<Bootstrap.Col xs={12} md={6}>
											<div className="d-flex">
												
												<div>
													<h2 className="me-4"
														style={{
															color: 'var(--job-display-border-color)'
														}}
													>
														Passionné
													</h2>
													<p className="text-secondary mb-0">
														Je suis passionné par la technologie et l'évolution de nos moyens de défense.
													</p>
												</div>
											</div>
										</Bootstrap.Col>
									</Bootstrap.Row>
								</Bootstrap.Col>
							</Bootstrap.Row>
						</Bootstrap.Col>
					</Bootstrap.Row>
					
				</Bootstrap.Container>
			
			</section>
			<section>
			<Bootstrap.Row  fluid style={{ width:'100%', padding :'0',margin:'0'}}>
				<Bootstrap.Container fluid style={{ padding: '10px', backgroundColor:'#e0e0e0' }}>
					<h2 
					style={{ padding: '10px', textAlign: 'center' , color: 'var(--bs-primary)', }}
					>
						Quelques fun facts</h2>
					<Carousel responsive={responsive} autoPlay={true} infinite={true} autoPlaySpeed={4000}>
						{funfacts.map((funfact, index) => (
							<Bootstrap.Col key={index} style={{ height: '100%',padding: '10px' }}>
								<Bootstrap.Card className="bg-light border-0 shadow-sm"
									style={{  
										height: '100%',
									}}
								>
									<Bootstrap.Card.Body className="text-center">
										<Bootstrap.Card.Title className="h5 mb-3"
										>{funfact.title}</Bootstrap.Card.Title>
										<Bootstrap.Card.Text className="text-secondary">{funfact.description}</Bootstrap.Card.Text>
									</Bootstrap.Card.Body>
								</Bootstrap.Card>
							</Bootstrap.Col>
						))}
					</Carousel>
				</Bootstrap.Container>
			</Bootstrap.Row>
			</section>
		</>
	);
};

export default About;