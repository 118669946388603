import React from 'react';
import ProjectCard from './ProjectCard';
import { Container, Col, Button} from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import projectList from '../asset/data/projectList';
import responsive from '../asset/data/responsive';





const Projects = () => {
  //read aftre the / in 


  return (
      <Container fluid style={{ padding: '10px', backgroundColor:'var(--bs-gray-dark)' }}>
        <h1 style={{ padding: '10px', textAlign: 'center' 
        , color: 'var(--bs-primary)', 
        }}>Mes Projets</h1>
        <p style={{ padding: '10px', textAlign: 'center', color: 'var(--bs-light)' }}>Voici quelques projets sur lesquels j'ai travaillé</p>

        <Carousel responsive={responsive} autoPlay={true} infinite={true} autoPlaySpeed={3000}>
          {projectList.map((project, index) => (
            <Col key={index} style={{ padding: '10px',
              height: '100%',
             }}>
              <ProjectCard
                image={project.image} 
                title={project.title}
                description={project.description}
                link={project.link}
                tools={project.tools}
                github={project.github}
                
              />
            </Col>
          ))}
        </Carousel>

        <div
        className="d-flex justify-content-center"
        >
          <Button href="/projects" variant="outline-primary" style={{ margin: '10px' }}>
            Aller a la page dediée aux projets 
          </Button>
        </div>

      </Container>
    );
};



export default Projects;