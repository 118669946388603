import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Collapsible from './Collapsible';

const Diplome = () => {

    return (
        <Container fluid style={{ paddingTop: '20px', paddingBottom: '20px', }}>
            <Row>
                <h1 style={{ padding: '10px', textAlign: 'center', color: 'var(--bs-light)' }}>
                    Parcours universitaire
                </h1>
            </Row>

            <Row>
                <Col style={{ padding: '10px', margin: 'auto' }} lg={8} md={12}>
                    <Collapsible style={{ padding: '0px', width: '100%' }}>
                        <Collapsible.Header style={{ backgroundColor: 'var(--white)', border: '0', }}>
                            
                            <h2 style={{ paddingTop: '10px', textAlign: 'center', margin: 'auto', color: 'var(--job-display-border-color)', fontWeight: 'bold' }}>
                                Gestion des Risques (MRI)
                                <p className='text-muted' style={{
                                    fontWeight: '600',
                                }}>
                                    Diplôme d'ingénieur
                                </p>
                            </h2>
                        </Collapsible.Header>
                        <Collapsible.Content style={{ backgroundColor: '#f0f1f2', padding: '10px',
                         }}>
                            <>
                            <h3 style={{color: 'var(--job-display-border-color)'}}>
                                    Diplome de l'INSA Centre Val de Loire
                                </h3>
                                <p style={{}}>
                                    Le but du département Maîtrise des Risques Industriels est de former des ingénieurs capables de maîtriser les risques industriels et de contribuer à la sécurité des personnes, des biens et de l'environnement. Y compris la sécurité des systèmes d'information.
                                </p>
                                <h4 style={{
                                    backgroundColor: 'var(--job-display-border-color)',
                                    padding: '5px 10px',
                                    marginTop: '20px',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    color: 'var(--bs-light)',
                                    marginBottom: '10px'
                                }}>
                                    Option de 4ème année : Systèmes Avancés
                                </h4>
                                <p style={{}}>
                                    Cette option permet d'acquérir des compétences en matière d'ingénierie systèmes. L'ingénierie système est une approche globale de la conception, de la réalisation et de la maintenance des systèmes complexes. Elle vise à garantir la cohérence et la compatibilité des éléments d'un système, à optimiser les performances et à minimiser les coûts. Cette approche a créé le génie logiciel par exemple.
                                </p>
                                <h4 style={{
                                    backgroundColor: 'var(--job-display-border-color)',
                                    padding: '5px 10px',
                                    marginTop: '20px',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    color: 'var(--bs-light)',
                                    marginBottom: '10px'
                                }}>

                                    Double diplôme en 5ème année
                                </h4>
                                <p style={{ textDecoration: 'bold' }}>
                                    5ème année remplacée par un double diplôme en Sécurité et Technologies Informatiques.
                                </p>
                                <h4 style={{
                                    backgroundColor: 'var(--job-display-border-color)',
                                    padding: '5px 10px',
                                    marginTop: '20px',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    color: 'var(--bs-light)',
                                    marginBottom: '10px'
                                }}>
                                    Liste de références
                                </h4>
                                <div style={{}}>
                                    <a href='https://syllabus.insa-cvl.fr/fr/maquette/list?dept=3&status=1' target='_blank' rel='noreferrer'>
                                        Syllabus Gestion des Risques, INSA CVL
                                    </a>
                                </div>
                            </>
                        </Collapsible.Content>
                    </Collapsible>
                </Col>
            </Row>
            <Row>
                <Col style={{ padding: '10px', margin: 'auto' }} lg={8} md={12}>
                    <Collapsible style={{ padding: '0px', width: '100%' }}>
                        <Collapsible.Header
                            style={{
                                backgroundColor: 'var(--white)',
                                border: '0'
                            }}
                        >
                            <h2 style={{ paddingTop: '10px', textAlign: 'center', margin: 'auto', color: 'var(--job-display-border-color)', fontWeight: 'bold' }}>
                                Sécurité Informatique (STI)
                                <p className='text-muted' style={{
                                    fontWeight: '600',
                                }}>
                                    Diplôme d'ingénieur
                                </p>
                            </h2>
                        </Collapsible.Header>

                        <Collapsible.Content style={{ backgroundColor: '#f0f1f2', padding: '10px', }}>
                            <>  
                                <h3 style={{color: 'var(--job-display-border-color)'}}>
                                    Diplome de l'INSA Centre Val de Loire
                                </h3>
                                <p style={{}}>
                                En tant qu'élève STI à l'INSA Centre-Val de Loire, j'ai acquis une solide maîtrise des technologies informatiques et une expertise en cybersécurité. Cette formation, dispensée sur le campus de Bourges, m'a permis de développer des compétences variées allant de l'administration système et réseaux à la gestion des risques en passant par la programmation et l'audit. À travers de nombreux projets, j'ai pu appliquer mes connaissances et me préparer à une carrière dynamique dans le domaine de la cybersécurité.
                                </p>
                                <h4 style={{
                                    backgroundColor: 'var(--job-display-border-color)',
                                    padding: '5px 10px',
                                    marginTop: '20px',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    color: 'var(--bs-light)',
                                    marginBottom: '10px'
                                }}>
                                    Option de 4ème année : Sécurité Fondamentale
                                </h4>
                                <p style={{}}>
                                    Cette option se concentre sur la création des preuves de sécurité. Une ouverture à l'informatique quantique et l'architecture sécurité des systèmes à micronoyaux comme Windows y sont abordés.
                                </p>
                                <h4 style={{
                                    backgroundColor: 'var(--job-display-border-color)',
                                    padding: '5px 10px',
                                    marginTop: '20px',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    color: 'var(--bs-light)',
                                    marginBottom: '10px'
                                }}>
                                    Option de 5ème année : Architecture, Administration, Audit et Analyse de Sécurité
                                </h4>
                                <p style={{}}>
                                    Les étudiants apprennent à identifier les menaces et les vulnérabilités, à mettre en place des mesures de protection et à réaliser des audits de sécurité et répondre aux incidents de sécurité.
                                </p>
                                <h4 style={{
                                    backgroundColor: 'var(--job-display-border-color)',
                                    padding: '5px 10px',
                                    marginTop: '20px',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    color: 'var(--bs-light)',
                                    marginBottom: '10px'
                                }}>
                                    Liste de références
                                </h4>
                                <div style={{}}>
                                    <a href='https://cyber.gouv.fr/formations/ingenieur-diplome-de-linstitut-national-des-sciences-appliquees-centre-val-de-loire-1' target='_blank' rel='noreferrer'>
                                        Label SecNumedu, ANSSI
                                    </a>
                                    <br />
                                    <a href='https://syllabus.insa-cvl.fr/fr/maquette/list?dept=1&status=1' target='_blank' rel='noreferrer'>
                                        Syllabus Sécurité Informatique, INSA CVL
                                    </a>
                                </div>
                            </>
                        </Collapsible.Content>
                    </Collapsible>
                </Col>
            </Row>

        </Container>
    );

};

export default Diplome;