import React from 'react';
import {
    Container, Row, Col, Button
} from 'react-bootstrap';
import headerBlob from '../asset/images/headerBlob.svg';

import pp from '../asset/images/pp.jpg';

import CV from '../asset/download/CVAlcorMONLLOR.pdf';

import { FaFilePdf } from 'react-icons/fa';


function HomeHeader() {
    return (
        <>
            <div
                style={{
                    minHeight: "100vh",
                    display: "flex",
                    background: "linear-gradient(215deg, #00d4ff 0%, #082b8f 5%, #031842 25%, #000 50%, #031842 75%, #082b8f 95%, #00d4ff 100%)",
                    margin: "0",
                    position: "fixed",
                    width: "100vw",
                    zIndex: "-1",
                }}
            >

            </div>
            <section className="hero-section "
                style={{
                     margin: "0px", 
                    padding : "0px",
                }}  
            >
                <Container className="h-100"
                    style={{
                        minHeight: "100vh",
                        display: "flex",
                        paddingBottom: "150px",
                        width: "100%",
                        zIndex: "-1"
                    }}
                >
                    <Row className="h-100 align-items-center"
                        style={{
                            margin: "auto",
                            width: "100%"

                        }}
                    >

                        <Col lg={6} className="order-lg-2 mb-5 mb-lg-0 d-flex justify-content-center align-items-center">
                            <div className="hero-image text-center text-lg-end" style={{
                                width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', zIndex: '10'

                            }}>
                                <img
                                    src={headerBlob}
                                    alt="Background"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '128%',  
                                        height: 'auto',
                                        transform: 'translate(-50%, -45%)',
                                        zIndex: '0',  
                                    }}
                                />
                                <svg width="100%" height="100%" viewBox="0 0 300 300" preserveAspectRatio="xMidYMid slice"
                                    style={{
                                        zIndex: '1',
                                        width: '90%',

                                    }}
                                >
                                    <defs>
                                        <pattern id="pattern" x="0" y="0" width="1" height="1" patternUnits="objectBoundingBox">
                                            <image href={pp} transform="translate(-50%, -50%)" preserveAspectRatio="xMidYMid slice"
                                                style={
                                                    {
                                                        width: "100%",
                                                        height: "100%"
                                                    }
                                                }
                                            />
                                        </pattern>
                                        <clipPath id="blobClip">
                                            <path
                                                fill="#343a40" d="M27.1,-35.2C35,-19.2,41.3,-9.6,42.5,1.3C43.8,12.1,40.1,24.2,32.1,33.2C24.2,42.2,12.1,48,-0.5,48.5C-13.1,49,-26.2,44.1,-42.5,35.2C-58.9,26.2,-78.4,13.1,-82.4,-4C-86.4,-21,-74.7,-42.1,-58.4,-58.1C-42.1,-74.2,-21,-85.2,-5.7,-79.5C9.6,-73.8,19.2,-51.2,27.1,-35.2Z"
                                                transform="translate(200 200 ) scale(2) 
                                                
                                                ">
                                            </path>

                                        </clipPath>
                                    </defs>
                                    <g clipPath="url(#blobClip)">
                                        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern)" />
                                    </g>
                                </svg>
                            </div>
                            

                        </Col>
                        <Col lg={6} className="order-lg-1"
                            style={{
                                margin: "auto"
                            }}
                        >
                            <div className="hero-content text-center text-lg-start">
                                <h1 className="display-4 fw-bold mb-4"
                                    style={{
                                        color: "var(--bs-primary)"
                                    }}
                                >
                                    Ingénieur en Cybersécurité & Gestion des Risques
                                </h1>
                                <h3 className="h4  mb-4"
                                    style={{
                                        color: "var(--bs-white)"
                                    }}
                                >
                                    Protéger les actifs numériques et garantir la confidentialité des données
                                </h3>
                                <p className="lead mb-5"
                                    style={{
                                        color: "var(--bs-white)"
                                    }}
                                >
                                    Mon parcours m'a permis de développer une connaissance solide en
                                    réseaux, systèmes et Cybersécurité, un esprit analytique et structurée.
                                    Je maintiens un homelab composé d'un NAS et de 4  nœuds Proxmox VE
                                    haute disponibilité pour expérimenter, simuler des environnements et
                                    renforcer mes compétences.
                                </p>
                                <div className="d-flex justify-content-center justify-content-lg-start gap-3">
                                    <Button href={CV} variant="outline-secondary" size="lg" target='_blank'>

                                        <FaFilePdf /> Télécharger mon CV

                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HomeHeader;